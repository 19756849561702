/* App.css */
body,
html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  height: 100%;
  overflow: hidden;
}
.pro-spin {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.login-page {
  position: relative;
  height: 100vh;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.content-container {
  display: grid;
  grid-template-columns: 3fr 2fr; /* 6-column layout */
  align-items: center;
  gap: 2rem;
  width: 90%;
  max-width: 1200px;
  z-index: 1;
}

.welcome-section {
  color: white;
  padding: 20px;
  margin-left: 100px;
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.logo {
  height: 120px;
}

.login-form-section {
  background: white;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
}

.p-float-label {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.password-field {
  margin-bottom: 30px;
}

.get-started-button {
  background: #7265e2;
  border: none;
  color: white;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease;
  border-radius: 10px;
}

.get-started-button:hover {
  background: #7265e2;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: content;
  z-index: -1; /* Send to background */
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay for contrast */
  z-index: 0;
}
.let-start {
  margin: 0;
  line-height: 1.5;
  font-weight: 400;
  font-size: 13px;
}
.login-acc {
  margin: 0 0 1rem;
  line-height: 1.5;
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 40px;
}

.float-label {
  margin: 0 0 1rem;
  line-height: 1.5;
  font-size: 14px;
  max-width: 400px;
}
.subtext {
  margin: 0 0 1rem;
  line-height: 1.5;
  font-size: 14px;
  max-width: 400px;
}
.emailInput {
  margin-bottom: 20px;
  border-radius: 8px;
}
.passwordInput {
  border-radius: 8px;
}

/* main */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 15rem;
  background-color: #ffffff;
  box-shadow: 0 0 10px #7265e2;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.sidebar .menu-container {
  flex-grow: 1;
  overflow-y: auto;
}

.sidebartieredmenu {
  width: 90%;
  background: #7265e2;
  display: flex;
  justify-content: space-around;
  border: none !important;
  height: 40px;
}
.menuFont {
  font-family: "Poppins", sans-serif !important;
}

.p-menu {
  width: 90% !important;
  border: none !important;
  /* display: flex !important; */
}

.cardStyle {
  position: relative;
  margin-left: 240px;
  transition: margin-left 0.8s ease-in-out;
  overflow: hidden;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px 100px;
  justify-content: space-between;
}

.heading {
  font-weight: 500;
  color: #000e3b;
}

.sidebarMenu.p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link {
  margin-bottom: 15px;
}

.active-tab {
  border-left: 3px solid #7265e2;
}

.active-tab {
  background-color: #f0f0f0;
}

.active-text {
  color: #7265e2;
}

/* dashboard */

.container {
  padding: 30px;
  background-color: #f5f7fa;
  height: 100vh;
  overflow: auto;
}
.heading-page {
  font-size: 32px;
  font-weight: 400;
}
.gridDashboard {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-top: -0.5rem;
}

.dashboardStyle {
  background: #fff;
  height: 150px;
  border-radius: 10px;
  overflow: hidden;
  padding: 0.5rem;
}

.dashboardText {
  font-size: 14px;
  color: white;
}

.dashboardImage {
  width: 60px;
}

.progressText {
  font-size: 14px;
  color: white;
}

.TabDashboard {
  background: #ffffff !important;
  border: 1px solid #e5e7eb;
  border-width: 0 0 2px 0;
  border-radius: 15px !important;
  padding: 20px;
}

.dashboardMargin {
  margin-top: -200px;
}
.progressBar {
  height: 10px !important;
  width: 80%;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.2) !important;
}
.vectorImage {
  width: 130px; /* Adjust size of the graph image */
  height: auto;
  object-fit: contain;
}
.graphTitle {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  text-align: left;
  margin-bottom: 1rem;
}

.card {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.mt-4 {
  margin-top: 1rem;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.grid-item {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.graphTitle {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  text-align: left;
  margin-bottom: 1rem;
}
.row {
  display: flex;
  gap: 20px;
}

.col-9 {
  flex: 3;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.col-3 {
  flex: 1;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.graph-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-performance-card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 25px; /* Increased padding */
  background-color: #f9f9f9;
  border-radius: 10px; /* Increased border radius */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* More prominent shadow */
  transition: all 0.3s ease-in-out;
}

/* Card hover effect */
.top-performance-card:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

/* Name Style */
.performance-name {
  font-size: 16px;
  font-weight: 500;
  color: #111111;
}

/* Medal Style */
.performance-medal {
  font-size: 24px;
}

.top-performances-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #111111;
  text-align: center;
}
.gold-border {
  border: 3px solid #ffb02e;
}

.silver-border {
  border: 3px solid silver;
}

.bronze-border {
  border: 3px solid #d3883e; /* Bronze color */
}
/* members */
.viewButton {
  color: white;
  background-color: #7265e2;
  border: 1px solid #7265e2;
  height: 30px;
}

.userDiv{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.perf-userID{
  padding-bottom: 5px;
}
/* user dashboard */
/* .highlight-green {
  background-color: green !important;
  color: white !important;
}

.highlight-yellow {
  background-color: yellow !important;
  color: black !important;
}

.highlight-red {
  background-color: red !important;
  color: white !important;
} */
/* .react-datepicker{
    width: 90%;
    display: flex;
    justify-content: space-evenly !important;
} */
.card-header{
  margin-top: 0;
}
.react-datepicker {
  width: 400px; /* Adjust the width as per your requirement */
}
.react-datepicker__day--today {
  background-color: transparent !important;
  color: inherit; /* Ensures the text color remains default */
  border: none; /* Removes any borders */
}
.react-datepicker__day-names {
  width: 400px;
  display: flex;
  justify-content: space-evenly;
  margin-top: 5px;
  font-weight: bold;
}
.react-datepicker__week {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
/* .react-datepicker__navigation--next {
  right: 2px;
  display: none !important;
} */

.p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
}

.floating-label {
  position: absolute;
  top: -14px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 14px;
  white-space: nowrap;
  font-weight: bold;
}